import React from "react"
/* NPM */
import parse from "html-react-parser"
import { chunkTechnologiesArray } from "../../functions"

const WhatWeUseBlock = ({ whatWeUseSection }) => {
  const { col1, col2, col3, col1Mobile, col2Mobile } = chunkTechnologiesArray(
    whatWeUseSection.technologyWeUse
  )

  return (
    <>
      {/* Desktop Layout */}
      <div
        className="columns block is-hidden-mobile"
        style={{ marginBottom: "0px" }}
      >
        <div className="column is-2">
          <ul>
            {col1.map((block, i) => {
              return (
                <li
                  key={"col1" + i}
                  className="is-size-5"
                  style={{ padding: "5px 0px" }}
                >
                  {parse(block.technologyUsed)}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="column is-2">
          <ul>
            {col2.map((block, i) => {
              return (
                <li
                  key={"col2" + i}
                  className="is-size-5"
                  style={{ padding: "5px 0px" }}
                >
                  {parse(block.technologyUsed)}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="column is-2">
          <ul>
            {col3.map((block, i) => {
              return (
                <li
                  key={"col3" + i}
                  className="is-size-5"
                  style={{ padding: "5px 0px" }}
                >
                  {parse(block.technologyUsed)}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* Mobile Layout */}
      <div className="columns block is-multiline is-mobile is-hidden-desktop is-hidden-tablet">
        <div className="column is-6 is-size-4-mobile">
          <ul>
            {col1Mobile.map((block, i) => {
              return (
                <li
                  key={"col1" + i}
                  className="is-size-5"
                  style={{ padding: "5px 0px" }}
                >
                  {parse(block.technologyUsed)}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="column is-6 is-size-4-mobile">
          <ul>
            {col2Mobile.map((block, i) => {
              return (
                <li
                  key={"col2" + i}
                  className="is-size-5"
                  style={{ padding: "5px 0px" }}
                >
                  {parse(block.technologyUsed)}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default WhatWeUseBlock
