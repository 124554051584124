import React from "react"

/* Images */
import vennUserNeeds from "../../assets/venn/user-needs@2x.png"
import vennBusinessGoals from "../../assets/venn/business-goals@2x.png"
import vennTechnicalConstraints from "../../assets/venn/technical-constraints@2x.png"

const VennDiagram = () => {
  return (
    <div
      className="venn ratio-square"
      style={{
        width: "100%",
      }}
    >
      <div className="content">
        <div
          style={{
            display: "grid",
            height: "100%",
          }}
        >
          <div
            style={{
              gridArea: "1/1",
              display: "grid",
            }}
          >
            <img
              src={vennUserNeeds}
              alt="business goals"
              data-aos="v1"
              data-aos-duration="2000"
              data-aos-once="false"
              style={{ width: "60%" }}
            />
          </div>
          <div
            style={{
              gridArea: "1/1",
              display: "grid",
            }}
          >
            <img
              src={vennBusinessGoals}
              alt="business goals"
              data-aos="v2"
              data-aos-duration="2000"
              data-aos-once="false"
              style={{
                justifySelf: "end",
                width: "60%",
              }}
            />
          </div>
          <div
            style={{
              gridArea: "1/1",
              display: "grid",
            }}
          >
            <img
              src={vennTechnicalConstraints}
              className="venn-circle-bottom"
              data-aos="v3"
              data-aos-duration="2000"
              data-aos-once="false"
              alt="business goals"
              style={{
                alignSelf: "end",
                justifySelf: "center",
                width: "70%",
              }}
            />
          </div>
          <div
            style={{
              gridArea: "1/1",
              display: "grid",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                justifySelf: "center",
                zIndex: "100",
              }}
            >
              <h1 className="is-size-3 is-size-3-mobile has-text-black venn-intersection-text">
                UX
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VennDiagram
