const chunk = require(`lodash/chunk`)


const chunkTechnologiesArray = technologies => {
  /* 
  use to turn a single array into multiple arrays to use in columns but split so that the reading order downwards is maintained. We use this function to help display the technologies we use. 
  
  e.g. we want columns like this:
  1   4   7
  2   5   8
  3   6   9

  instead of:
  1   2   3
  4   5   6
  7   8   9
  */

  const numItems = technologies.length

  const numColumns = 3
  const numColummnsMobile = 2

  const remainder = numItems % numColumns
  const remainderMobile = numItems % numColummnsMobile

  const quotient =
    remainder === 0
      ? Math.trunc(numItems / numColumns)
      : Math.trunc(numItems / numColumns) + 1

  const quotientMobile =
    remainderMobile === 0
      ? Math.trunc(numItems / numColummnsMobile)
      : Math.trunc(numItems / numColummnsMobile) + 1
  /*
  const lengthOfChunk = remainder === 0 ? quotient : quotient + 1
  const lengthOfChunkMobile =
    remainderMobile === 0 ? quotientMobile : quotientMobile + 1
*/
  const startingPoints = chunk(technologies, quotient)
  const startingPointsMobile = chunk(technologies, quotientMobile)
  return {
    col1: startingPoints[0],
    col2: startingPoints[1],
    col3: startingPoints[2],
    col1Mobile: startingPointsMobile[0],
    col2Mobile: startingPointsMobile[1],
  }
}

const showText = text => {
  return text
}

export { chunkTechnologiesArray, showText }
