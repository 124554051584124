import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import parse from "html-react-parser"
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import HowWeDoItBlock from "../components/About/HowWeDoItBlock"
import WhatWeUseBlock from "../components/About/WhatWeUseBlock"
import VennDiagram from "../components/About/VennDiagram"
const AboutTemplate = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])
  return (
    <Layout
      page={page}
      showMenu={false}
      showFooter={page.pageSettings.showFooter}
    >
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />

      <PageWrapper additionalClassName="about">
        <PageTitleSection
          title="A bit<br />about us."
          subtitle="We'll elevate your business to new heights."
        />
        <Section>
          <div className="column is-7 is-size-5 is-size-5-mobile  has-text-weight-normal">
            We're a team of quirky creatives, technical boffins and product
            development experts committed to helping organisations stand out in
            busy marketplaces.
            <br />
            <br />
            We do this by developing super-fast bespoke websites with a focus on
            high-end creative design, all backed by our deep commercial
            experience and technical know-how.
          </div>
        </Section>
        <Section
          dataAos="fade-up"
          additionalClasses="has-background-black-bis double-top-padding"
        >
          <div className="columns is-multiline">
            <div className="column is-12">
              <SectionTitle>
                {parse(page.AboutUsSettings.vennDiagramIntro.title)}
              </SectionTitle>
            </div>

            <div className="content-padding column is-12-touch is-6-desktop">
              <div className="is-size-3 is-size-3-mobile subtitle has-text-weight-bold">
                {parse(page.AboutUsSettings.vennDiagramIntro.subTitle)}
              </div>
              <div className="content-padding is-size-5 is-size-4-mobile has-text-weight-normal">
                {parse(page.AboutUsSettings.vennDiagramIntro.content)}
              </div>
              <div
                className="is-hidden-touch is-size-5 is-size-4-mobile has-text-weight-normal"
                style={{ padding: "40px 0px" }}
              >
                {parse(page.AboutUsSettings.vennDiagramIntro.contentBelowVenn)}
              </div>
            </div>
            <div className="content-padding  column is-5-desktop is-12-touch is-offset-0-touch is-offset-1-desktop">
              <VennDiagram />
            </div>
            <div className="is-hidden-desktop is-size-5 is-size-4-mobile has-text-weight-normal">
              {parse(page.AboutUsSettings.vennDiagramIntro.contentBelowVenn)}
            </div>
          </div>
        </Section>

        <Section dataAos="fade-up">
          <SectionTitle>
            {parse(page.AboutUsSettings.whatWeUseSection.title)}
          </SectionTitle>

          <div className="content-padding columns">
            <div className="column">
              <h2 className="is-size-3 is-size-3-mobile has-text-weight-semibold">
                {parse(page.AboutUsSettings.whatWeUseSection.content)}
              </h2>
            </div>
          </div>

          <WhatWeUseBlock
            whatWeUseSection={page.AboutUsSettings.whatWeUseSection}
          />
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default AboutTemplate

export const AboutPageQuery = graphql`
  query AboutPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      AboutUsSettings {
        vennDiagramIntro {
          title
          subTitle
          content
          contentBelowVenn
        }
        howWeDoItSection {
          title
          content
          howWeDoItBlocks {
            title
            content
          }
        }
        whatWeUseSection {
          title
          content
          technologyWeUse {
            technologyUsed
          }
        }
      }
    }
  }
`
